import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class DepartmentService {
  @Inject(HttpService) private http!: HttpService;

  // division
  public getBuList(): Promise<any> {
    return this.http.get('/api/dept/division');
  }
  // 职位
  public getPositionList(): Promise<any> {
    return this.http.get('/api/dept/position');
  }

  // 部门树
  public async getDeptTree(): Promise<Dict<any>[]> {
    return JSON.parse(JSON.stringify(await this.http.get('/api/dept/tree')).replace(/\"children"\:\[]/g, '"no":0'));
  }

  // 部门树 === hr core
  public async getDepartemntTree(): Promise<Dict<any>[]> {
    return this.http.get('/api/hr/dept/tree')
  }

  // 查询部门人员
  public async getMembersOfDepart(param: any): Promise<any> {
    return this.http.get('/api/hr/dept/user', param)
  }

  public async getDepartMembersByID(deptID: any): Promise<any> {
    return this.http.get(`/api/hr/dept/${deptID}`);
  }

  public async searchDepartAndUserByKeywords(param: any): Promise<any> {
    return this.http.get('/api/user/group/hrcore/search', param);
  }

  public async getDepartmentTreeAuthority(): Promise<Dict<any>[]> {
    return this.http.get('/api/hr/dept/tree/authority');
  }

  // HR Core 用户分组 查询 Department Tree
  public async getDepartmentTreeAuthorityManager(): Promise<Dict<any>[]> {
    return this.http.get('/api/hr/dept/authority/management');
  }
  //HR Core 用户division 查询 禁用启用division接口
  public async getDivisionSelector(): Promise<Dict<any>[]> {
    return this.http.get('/api/hr/dept/division');
  }
  // new NUN FF 部门树查询列表 同 getPharmaTree()”
  public async getNunFFTree(): Promise<Dict<any>[]> {
    return this.http.get(`/api/nonff/tree`);
  }

  //第三方和内部员工部门数选择
  public disableDepartmentTreeAuthority(param: Dict<any>): Promise<any> {
    return this.http.put('/api/hr/dept/disable', param);
  }


  //不区分第三方和内部员工部门数选择
  public disableALlInDepartmentTreeAuthority(param: Dict<any>): Promise<any> {
    return this.http.put('/api/hr/syn/status/edit', param);
  }

  //HR Core 用户division 选中division生效
  public disableDivisionAuthority(param: Dict<any>): Promise<any> {
    return this.http.put('/api/hr/division/disable', param);
  }

  public getPharmaTree(): Promise<any> {
    return this.http.get(`/api/user/uploading/pharma/tree`);
  }

  public getOncoTree(): Promise<any> {
    return this.http.get(`/api/user/uploading/onco/tree`);
  }

  public async searchPharmaDepartAndUserByKeywords(param: any): Promise<any> {
    return this.http.get('/api/user/group/pharma/search', param);
  }
  // NOnff serrch
  public async searchNonffDepartAndUserByKeywords(param: any): Promise<any> {
    return this.http.get('/api/nonff/search', param);
  }
  // NOnff roleList
  public async roleNonfflist(param: any): Promise<any> {
    return this.http.get('/api/nonff/role-list', param);
  }
  // NOnff BUList
  public async BuNonfflist(param: any): Promise<any> {
    return this.http.get('/api/nonff/bu-list', param);
  }
  public async searchOncoDepartAndUserByKeywords(param: any): Promise<any> {
    return this.http.get('/api/user/group/onco/search', param);
  }
  public async getPharmaPositionEffectiveCount(param: any): Promise<any> {
    return this.http.get('/api/user/uploading/post/num/pharma', param);
  }
  public async getOncoPositionEffectiveCount(param: any): Promise<any> {
    return this.http.get('/api/user/uploading/post/num/onco', param);
  }
  public async getNunffPositionEffectiveCount(param: any): Promise<any> {
    return this.http.post('/api/nonff/num', param);
  }



}
