




















































import { Component, Ref, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import hrcoreInfo from './hrcoreInfo.vue'; // 这里用的是pharma组件。后期需要换为或者改里面的内容和名字为HR Core
import { UserUploadService } from '@/services/user-uploading';
import { DialogService } from '@/services/common/dialog.service';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
import { ElTabs } from 'element-ui/types/tabs';
import { throttle } from 'lodash';
import PermissionSelector from '@/components/PermissionSelector/PermissionSelector.vue';
import { uuid } from '@/services/utils';
import { DepartmentService } from '@/services/department-service';

@Component({
  components: { hrcoreInfo ,PermissionSelector}
})
export default class Manager extends Vue {
  public fileId!: number;
  public id!: string;
  public upDateTime: string = '';
  private hrcoredata: any = {};
  private activeName: string = '2';
  private searchParams:any = {};
  public loading: boolean = false;
  private targetLeftBar!: HTMLElement;
  private resizeLine!: HTMLElement;
  @Ref('tabsEl') private tabsEl!: ElTabs;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(DialogService) private dialogService!: DialogService;
  @Inject(DepartmentService) private departmentService !: DepartmentService;
  private targetLeftBarResizeHandlerProxy = throttle((event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.targetLeftBarResizeHandler(event);
  }, 200);

  // i8n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async mounted(): Promise<void> {
    await this.$nextTick();
    this.initResizeLeftBar();
    this.updatetime()
  }

  // 最后更新时间
  public async updatetime(): Promise<void> {
    const res = await this.userUploadService.getupdateTime();
    if(res)this.upDateTime = '(' + res.createdtime + ' 最后更新' + ')';
  }

  public hrcoreparams (val:any){
    this.searchParams = val;
  }

  public async updatePermision(): Promise<void> {
    const key = uuid();
    const res = await this.dialogService.open(
      this.i18n.background_mgmt.department_authority_management,
      PermissionSelector,
      {  },
      { key, height: 'auto' }
    );
  }

  // 下载方法
  public successdownload(): void {
    this.loading = true
    const date = new Date();
    this.userUploadService.addressbookdownload(
      "",
      this.searchParams,
      `${this.userUploadService.getFileNamePrefix(this.activeName)}${moment(
        date
      ).format('YYYYMMDDHHmm')}.xlsx`
    ).then(res=>{
      console.log('res',res)
      this.loading = false
    });
  }

  // 左边导航栏的宽度可以调节
  private initResizeLeftBar(): void {
    this.removeListener();
    setTimeout(
      (() => {
        this.targetLeftBar = this.tabsEl.$el.querySelector(
          'div.staffdataupload_leftbar'
        ) as HTMLElement;
        this.resizeLine = this.targetLeftBar.querySelector(
          'div.resize-line'
        ) as HTMLElement;
        if (this.resizeLine) {
          this.resizeLine.addEventListener(
            'mousedown',
            this.addResizeLineMousemoveListener
          );
          document.addEventListener(
            'mouseup',
            this.removeResizeLineMousemoveListener
          );
        }
      }).bind(this)
    );
  }

  private addResizeLineMousemoveListener(event: any): void {
    document.addEventListener(
      'mousemove',
      this.targetLeftBarResizeHandlerProxy
    );
  }
  private removeResizeLineMousemoveListener(event: any): void {
    document.removeEventListener(
      'mousemove',
      this.targetLeftBarResizeHandlerProxy
    );
  }

  private targetLeftBarResizeHandler(event: any): void {
    const rect = this.targetLeftBar.getBoundingClientRect();
    this.targetLeftBar.style.width =
      rect.width - (rect.right - event.clientX) + 5 + 'px';
  }
  private removeListener(): void {
    if (this.resizeLine) {
      this.resizeLine.removeEventListener(
        'mousedown',
        this.addResizeLineMousemoveListener
      );
      this.resizeLine.removeEventListener(
        'mouseup',
        this.removeResizeLineMousemoveListener
      );
    }
  }
}
