



































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import hrcoreInfo from './hrcoreInfo.vue';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { UserUploadService } from '@/services/user-uploading';
import { hrcoreTableConfig, searchConfig } from './index.config';
import { cloneDeep, assign, isArray } from 'lodash';

@Component({
  components: { hrcoreInfo }
})
export default class Manager extends Vue {
  public pageInfo: any = {
    pageSize: 10,
    pageNum: 1,
    deptId:'',
    chineseName:'',
    employeeId:'',
    companyEmail:'',
    divisionCodeList:[],
    permissionIdList:[], // 应用权限管理
    profileIdList:[] // 角色
  };
  public pageInfodatatotal:any = {
    total: null
  };
  public loading: boolean = false;
  public filterText: string = '';
  public val: any;
  public treeList: any[] = [];
  public treeloading: boolean = false;
  public tableConfig: any[] = hrcoreTableConfig;
  public table: any[] = [];
  public isShowDropMenu: boolean = false;
  @Prop({ required: true })
  public hrcoredata!: any;
  private searchConfig: ISearchConfig[] = [];
  private appNameOptions: any[] = [];
  private appNameTags: any[] = [];
  private formSearch: any = {};

  private props: any = {
    label: 'dept_name',
    children: 'children',
    isLeaf: this.isLeaf
  };

  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  // private uploadResult: Dict<any> = {};

  @Watch('searchText')
  public searchText(val: any): void {
    (this.$refs as any).tree.filter(val);
  }

  public mounted(): void {
    this.initData();

    // 默认展示部门树
    this.getTree();
  }

  get nameTags(): any[] {
    // slice不会改变原数组，选取start位置和end位置的元素
    return this.appNameTags.slice(0) || [];
  }

  // 获取Tab数据接口方法
  public async getList(): Promise<void> {
    try {
      this.loading = true;
      const params = this.pageInfo

      // 清除入参为空参数
      var removePropertyOfNull = function(obj: { [x: string]: any}){
        Object.keys(obj).forEach(item=>{
          if(!obj[item] || obj[item].length == 0) delete obj[item]
        })
        return params;
      };
      removePropertyOfNull(params);
      // 调用tab表格里面的数据接口方法
      const data = await this.userUploadService.getdepartmentPeople(params);
      this.pageInfo.pageSize = data.size // 每页展示的条数
      this.pageInfo.pageNum = data.current // 当前的页数
      this.pageInfodatatotal.total = data.total
      const result = data.records;

      if (!result) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      } else {
        result.forEach((element: any) => {
          if (element.nvsWcPermissionName) {
            this.appNameTags = element.nvsWcPermissionName.split(',');
            element.nvsWcPermissionName = this.appNameTags;
          }
        });
      }
      this.table = result;
    } finally {
      this.loading = false;
    }
  }

  // 右边form表单中点击搜索的方法
  public async getfieldList(val?: any): Promise<void> {
    this.pageInfo.chineseName = val.name;
    this.pageInfo.employeeId = val.personnelNumber;
    this.pageInfo.companyEmail = val.email;

    this.pageInfo.permissionIdList = val.applicationIds; // 应用访问权限
    this.pageInfo.profileIdList = val.nvsWcProfileName // 角色
    this.pageInfo.divisionCodeList = val.divisions // division

    this.pageInfo.pageSize = 10;
    this.pageInfo.pageNum = 1;
    this.getList();
  }

  @Watch('pageInfo', { deep: true, immediate: true })
    public paramsInfo(value: string, data: any) {
    this.$emit("hrcoreparams",value)
  }

  @Watch('hrcoredata', { deep: true, immediate: true })
  public filterNode(value: string, data: any) {
    if (!value) {
      return true;
    }
  }

  // 点击通讯录tree树的节点方法
  public async handleNodeClick(data: any): Promise<void> {
    this.pageInfo.pageSize = 10;
    this.pageInfo.pageNum = 1;
    this.pageInfo.chineseName = '';
    this.pageInfo.employeeId = '';
    this.pageInfo.companyEmail = '';
    this.pageInfo.divisionCodeList = [];
    this.pageInfo.permissionIdList = [];
    this.pageInfo.profileIdList = [];
    this.formSearch = {};
    this.pageInfo.deptId = data.dept_id;

    this.getList();
  }

  // 根据入参过滤 tree部门。
  public async queryTree(val: any): Promise<void> {
    if (val) {
      this.treeloading = true;
      const res = await this.userUploadService.gettreeDepartmentname({departmentName:val})
      this.treeList = res
    }else{
      this.getTree();
      this.treeloading = false;
      return;
    }
    this.treeloading = false;
  }

  // 获取所有部门树接口
  private async getTree(): Promise<void> {
    this.treeloading = true;
    const res: any = await this.userUploadService.getDepartmentTree();
    if (res && isArray(res)) {
      this.treeList = [
        {
          dept_name: '全部',
          treeLevel: 'top',
          value: 1,
          children: res
        }
      ];
    }
    this.treeloading = false;
    this.getList();
  }

  private isLeaf(data: any, node: any): boolean {
    if (data.children && data.children.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  private async initData(): Promise<void> {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      const division:any = (await this.userUploadService.getDepartmentdivision()) || [];
      this.searchConfig[2].options = (await this.userUploadService.getprofilelist()) || [];
      const divisionlist:any = []
      division.forEach((item:any,index:number)=>{
        divisionlist.push({id:item.deleted,label:item.division_name,name:item.id,value:item.division_name})
      })
      this.searchConfig[4].options = divisionlist

      // 应用访问权限
      this.appNameOptions =
        (await this.userUploadService.getApplicationNames({
          divisionCode: '1'
        })) || [];
      this.searchConfig[5].options = [];
      if (this.searchConfig[5].options.length === 0) {
        if (this.appNameOptions && this.appNameOptions.length) {
          this.appNameOptions.forEach((item: any) => {
            (this.searchConfig[5].options as any).push({
              value: item.id,
              label: item.channelName
            });
          });
        }
      }
      this.getList();
    } finally {
      this.loading = false;
    }
  }

  // 点击树左边的三角形触发的方法
  private loadNode(node: any, resolve: any) {
    setTimeout(() => {
      const data = node.data.children;
      if (data) {
        resolve(data);
      }
    }, 500);
  }

  public handleSizeChange(pageSize: number) {
    this.pageInfo.pageSize = pageSize;
    this.getList();
  }
  public handleCurrentChange(pageNumber: number) {
    this.pageInfo.pageNum = pageNumber;
    this.getList();
  }
}
