































import { Component, Vue } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { UserService } from '@/services/user-service';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { UserUploadService } from '@/services/user-uploading';
import DepartmentAuthSelector from '@/components/PermissionSelector/DepartmentAuthSelector.vue';
import { cloneDeep } from 'lodash';
import { DepartmentService } from '@/services/department-service';

@Component({
  components: { DepartmentAuthSelector }
})
export default class PermissionSelector extends Vue {
  private permissionUpdateList: any[] = [];
  private data!: any;
  public internalUserType: any = '内部员工';
  public externalUserType: any = '三方员工';
  public activeName: string = 'internalLabel';
  public loading: boolean = false;

  @Inject(UserService) private userService!: UserService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(DepartmentService) private departmentService!: DepartmentService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async created(): Promise<void> {
    this.initData(this.data);
  }

  private async initData(data: any): Promise<void> {
    console.log('initData', data);
  }

  public async submitForm(): Promise<any> {
    console.log(this.permissionUpdateList);
    try {
      await this.$confirm(
        '启用的部门将被同步到企业微信通讯录，请确认是否要继续操作',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      );
      this.loading = true;
      await this.departmentService.disableDepartmentTreeAuthority(
        this.permissionUpdateList
      );
      this.loading = false;
      this.$notify({
        title: '成功',
        message: '保存成功',
        type: 'success'
      });
      this.dialog.close(
        CloseStatus.confirm,
        this.permissionUpdateList,
        this.data.key
      );
    } catch (e) {
      this.$notify.info({
        title: '取消',
        message: '更新取消'
      });
    }
  }

  public cancelForm(): void {
    this.dialog.close(CloseStatus.close, void 0, this.data.key);
  }

  public toggleTab(val: any): void {
    console.log('toggleTab', val);
  }

  public addDepartmentLog(data: any): void {
    console.log('addDepartmentLog', data);
    if (data.userType == this.internalUserType) {
      let updatedDepartmentLog = {
        deptId: data.departmentData.dept_id ? data.departmentData.dept_id : '',
        changedUserType: 'internal',
        newStatus: data.newStatus
      };
      this.permissionUpdateList.push(updatedDepartmentLog);
    } else if (data.userType == this.externalUserType) {
      let updatedDepartmentLog = {
        deptId: data.departmentData.dept_id ? data.departmentData.dept_id : '',
        changedUserType: 'external',
        newStatus: data.newStatus
      };
      this.permissionUpdateList.push(updatedDepartmentLog);
    }
  }
}
