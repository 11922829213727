import { I18nService } from '@cds/i18n';
import { ISearchConfig } from '@/components/SearchTable/index.d';

// 通讯表格渲染
export const hrcoreTableConfig = [
  {
    prop: 'employeeId',
    label: '员工号'
  },
  {
    prop: 'nvsWcProfileName',
    label: '角色'
  },
  {
    prop: 'companyEmail',
    label: 'E-mail'
  },
  {
    prop: 'divisionName',
    label: 'Division'
  },
  {
    prop: 'departmentName',
    label: 'Post/Dept'
  },
];

// form表单里面的搜索框添加
export const searchConfig: ISearchConfig[] = [
  // 姓名搜索输入框
  {
    type: 'input',
    key: 'name',
    category: 'common_content',
    subclass: 'user'
  },
  // 员工号搜索输入框
  {
    type: 'input',
    key: 'personnelNumber',
    category: 'common_content',
    subclass: 'id'
  },
  // 角色
  {
    type: 'select',
    multiple: true,
    key: 'nvsWcProfileName',
    category: 'common_content',
    subclass: 'role',
    options: []
  },

  //email搜索输入框
  {
    type: 'input',
    key: 'email',
    category: 'common_content',
    subclass: 'email'
  },

  // Divison
  {
    type: 'select',
    multiple: true,
    key: 'divisions',
    category: 'common_content',
    subclass: 'division',
    options: []
  },
  // {
  //   type: 'select',
  //   multiple: true,
  //   key: 'promotionGrids',
  //   category: 'background_mgmt',
  //   subclass: 'promotionGrid',
  //   options: []
  // },

  //应用权限选择下拉菜单
  {
    type: 'select',
    multiple: true,
    key: 'applicationIds',
    category: 'common_content',
    subclass: 'app_authority',
    options: []
  }
];
